import styled from 'styled-components';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from './Button';
import Plus from './Plus';
import { colors } from '../utils/siteVars';
import { parseDate } from '../utils/parseDate';
import { serviceOptions } from '../utils/data';
import { useContext } from 'react';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import axios from 'axios';
import { parseSEK } from '../utils/parseSEK';
import DateInput from './DateInput';

const StyledOfferForm = styled.form`
  .label {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }
  .pointer {
    cursor: pointer;
  }
  .selected-technicians {
    margin-bottom: 1rem;

    .selected-technician {
      margin-bottom: 0.3rem;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }
    }
  }
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .trip {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .flex {
    display: flex;
  }
  .jce {
    justify-content: flex-end;
  }
  .jcfe {
    justify-content: flex-end;
  }
  .asc {
    align-self: center;
  }
  .c {
    text-align: center;
  }
  .m0 {
    margin: 0;
  }
  .mright2 {
    margin-right: 2rem;
  }
  .mbottom05 {
    margin-bottom: 0.5rem;
  }
  .mbottom1 {
    margin-bottom: 1rem;
  }

  .service-row {
    .row-sum {
      align-self: center;
      margin-bottom: 1rem;
      text-align: center;
    }
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr 20px;
    column-gap: 0.5rem;
    align-items: flex-start;
    p {
      margin: 0;
      font-weight: 12px;
      margin-bottom: 0.3rem;
    }
    .service-input-wrapper {
      position: relative;
      .cross {
        display: none;
        cursor: pointer;
        position: absolute;
        align-items: center;
        height: calc(100% - 0.5rem);
        top: 0;
        margin-right: 2rem;
        right: 0;
      }
      &:hover .cross {
        display: flex;
      }
    }
    input,
    select,
    textarea {
      margin-bottom: 0.5rem;
      font-size: 12px;
      padding: 0.8rem;
    }
  }
  .bold {
    font-weight: 700;
  }
  @media (max-width: 1000px) {
    .service-row {
      grid-template-columns: 3fr 3fr 2fr;
      &.headers {
        display: none;
      }
      &.user-row {
        margin-bottom: 1rem;
        position: relative;
        textarea {
          grid-column: 1 / 3;
        }
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          width: 100%;
          background: ${colors.lightGrey};
          content: '';
        }
      }
    }
  }
`;

const OfferForm = ({ data, customer, users, handleSubmit }) => {
  const initialFormData = {
    offerDate: '',
    offerNumber: '',
    status: 'Utkast',
    customerReferences: [],
    sellerReferences: [],
    validUntilDate: '',
    latePaymentInterest: 0.12,
    deliveryTerms: '',
    deliveryMethod: '',
    table: [],
    other: [],
  };

  const state = useContext(GlobalStateContext);

  const [formData, setFormData] = useState(initialFormData);
  const [sortedServices, setSortedServices] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getNextOfferNumber = async () => {
    try {
      const url = `${process.env.GATSBY_BACKEND_API_URL}offers/offer-number/next`;
      const headers = {
        Authorization: 'Bearer ' + state.token,
      };
      const { data: offerNumber } = await axios.get(url, { headers });
      setFormData({
        ...formData,
        offerNumber,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const sortServices = () => {
    const sorted = serviceOptions.sort((a, b) => {
      if (a.sortBottom) return 1;
      return a.name.localeCompare(b.name, 'sv');
    });
    setSortedServices(sorted);
  };

  const totalSum = () => {
    const rowSums = formData.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce((acc, curr) => acc + curr, 0);
    return totalSum;
  };

  useEffect(() => {
    sortServices();
    if (data) setFormData(data);
    else getNextOfferNumber();
    //eslint-disable-next-line
  }, [data]);

  return (
    <StyledOfferForm
      onSubmit={(e) => {
        e.preventDefault();
        console.log(formData);
        handleSubmit(formData, customer);
      }}
    >
      <div className="trip">
        <div>
          <p className="label">Offertdatum</p>
          <DateInput
            required
            name="offerDate"
            value={formData.offerDate ? parseDate(formData.offerDate) : ''}
            onChange={(e) => {
              const updated = { ...formData };
              updated.offerDate = e.target.value;
              const validTime = 1000 * 60 * 60 * 24 * 90; // 30 days
              const validUntil = parseDate(
                new Date(new Date(e.target.value).getTime() + validTime)
              );
              updated.validUntilDate = validUntil;
              setFormData(updated);
            }}
          />
        </div>
        <div>
          <p className="label">Giltig tom</p>
          <DateInput
            required
            name="validUntilDate"
            value={formData.offerDate ? parseDate(formData.validUntilDate) : ''}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="label">Offertnummer</p>
          <input
            required
            type="text"
            name="offerNumber"
            value={formData.offerNumber}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="dual">
        <div>
          <p className="label">Kundens referens</p>
          {/* eslint-disable-next-line */}
          <select
            required
            name="customerReferences"
            value={formData.customerReferences[0]?._id || ''}
            onChange={(e) => {
              const person = customer.contactPersons.find(
                (p) => p._id === e.target.value
              );
              setFormData({ ...formData, [e.target.name]: [{ ...person }] });
            }}
          >
            <option value="" disabled>
              Välj kontaktperson
            </option>
            {customer.contactPersons.map((person) => (
              <option key={person._id} value={person._id}>
                {person.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className="label">Vår referens</p>
          {/* eslint-disable-next-line */}
          <select
            required
            name="sellerReferences"
            value={formData.sellerReferences[0]?._id || ''}
            onChange={(e) => {
              const person = users.find((p) => p._id === e.target.value);
              setFormData({ ...formData, [e.target.name]: [person] });
            }}
          >
            <option value="" disabled>
              Välj person
            </option>
            {users.map((person) => (
              <option key={person._id} value={person._id}>
                {person.firstName} {person.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="dual">
        <div>
          <p className="label">Leveransvillkor</p>
          {/* eslint-disable-next-line */}
          <select
            name="deliveryTerms"
            value={formData.deliveryTerms}
            onChange={handleChange}
          >
            <option value="">-</option>
            <option>Fritt vårt lager</option>
          </select>
        </div>
        <div>
          <p className="label">Leveranssätt</p>
          {/* eslint-disable-next-line */}
          <select
            name="deliveryMethod"
            value={formData.deliveryMethod}
            onChange={handleChange}
          >
            <option value="">-</option>
            <option>Schenker</option>
            <option>Tas med av H2O</option>
          </select>
        </div>
      </div>
      <p className="label">Övrigt</p>
      <textarea
        value={formData.other?.join('\n')}
        onChange={(e) => {
          setFormData({
            ...formData,
            other: e.target.value.split('\n'),
          });
        }}
      />
      <hr />
      <p className="bold">Rader</p>
      <div className="service-row headers">
        <p>Benämning</p>
        <p>Antal</p>
        <p>Enhet</p>
        <p>Á-pris</p>
        <p className="c">Summa</p>
      </div>
      {formData.table.map((row, i) => (
        <div className="service-row user-row" key={`service_row_${i}`}>
          <div>
            <datalist id="offer-form-datalist">
              {sortedServices.map((service) => (
                <option
                  key={`offer_service_option_${i}_${service.name}`}
                  disabled={service.disabled}
                >
                  {service.name}
                </option>
              ))}
            </datalist>
            <div className="service-input-wrapper">
              <input
                list="offer-form-datalist"
                required
                value={row.service}
                onChange={(e) => {
                  const updated = [...formData.table];
                  updated[i].service = e.target.value;
                  const service = serviceOptions.find(
                    (s) => s.name === e.target.value
                  );
                  if (service) {
                    updated[i].id = service.id;
                    updated[i].priceExVat =
                      serviceOptions.find((s) => s.name === e.target.value)
                        ?.priceExVat || 0;
                  }
                  setFormData({
                    ...formData,
                    table: updated,
                  });
                }}
              />
              {/* eslint-disable-next-line */}
              <div
                className="cross"
                onClick={() => {
                  const updated = [...formData.table];
                  updated[i].service = '';
                  updated[i].id = '';
                  updated[i].priceExVat = 0;
                  setFormData({ ...formData, table: updated });
                }}
              >
                <Plus color={'black'} cross />
              </div>
            </div>
          </div>
          <input
            placeholder="Antal"
            type="number"
            value={row.quantity}
            required
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].quantity = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />

          {/* eslint-disable-next-line */}
          <select
            required
            value={row.unit}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].service = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <option value="" disabled>
              Välj enhet
            </option>
            <option>st</option>
          </select>
          <input
            placeholder="Á-pris"
            type="number"
            value={row.priceExVat}
            required
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].priceExVat = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />
          <p className="row-sum">{parseSEK(row.priceExVat * row.quantity)}</p>
          {/* eslint-disable-next-line */}
          <div
            className="pointer asc mbottom1"
            onClick={() => {
              const updated = [...formData.table];
              updated.splice(i, 1);
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <Plus cross color={colors.red} />
          </div>
        </div>
      ))}
      <Button
        type="button"
        small
        onClick={() => {
          const updated = [...formData.table];
          updated.push({
            service: '',
            quantity: 1,
            unit: 'st',
            priceExVat: 0,
          });
          setFormData({
            ...formData,
            table: updated,
          });
        }}
      >
        Lägg till rad
      </Button>
      <div className="flex jcfe mright2 mbottom05">
        <p className="m0">
          Summa ex moms: <span>{parseSEK(totalSum())}</span>
        </p>
      </div>
      <div className="flex jcfe mright2">
        <p className="m0">
          Summa ink moms: <span>{parseSEK(totalSum() * 1.25)}</span>
        </p>
      </div>
      <hr />
      <p className="label">Status</p>
      {/* eslint-disable-next-line */}
      <select
        onChange={(e) => {
          setFormData({
            ...formData,
            status: e.target.value,
          });
        }}
        value={formData.status}
      >
        <option>Utkast</option>
        <option>Klar</option>
      </select>
      <Button type="submit" small>
        Spara
      </Button>
    </StyledOfferForm>
  );
};

export default OfferForm;
