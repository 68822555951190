export const parseDate = (date) => {
  if (!date) return null;
  if (date.length === 0) return null;
  const parsedDate = new Date(date).toLocaleDateString('sv');
  return parsedDate;
};

export const parseDateTime = (date) => {
  if (!date) return null;
  if (date.length === 0) return null;
  const preformatted = new Date(date).toString();
  const formatted = new Date(preformatted).toLocaleString('sv', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formatted;
};

export const normalizeDate = (date, fallback = '') => {
  return date ? parseDate(date) : fallback;
};

export const msToTimeStr = (ms) => {
  if (ms < 0) return null;

  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));

  const duration = [];
  if (days > 0) duration.push(`${days}d`);
  if (hours > 0) duration.push(`${hours}h`);
  if (minutes > 0) duration.push(`${minutes}m`);
  if (seconds > 0) duration.push(`${seconds}s`);

  return duration.join(' ');
};
