// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-edit-customer-js": () => import("./../../../src/pages/admin/edit-customer.js" /* webpackChunkName: "component---src-pages-admin-edit-customer-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-create-protocol-insp-js": () => import("./../../../src/pages/create-protocol/insp.js" /* webpackChunkName: "component---src-pages-create-protocol-insp-js" */),
  "component---src-pages-create-protocol-kont-js": () => import("./../../../src/pages/create-protocol/kont.js" /* webpackChunkName: "component---src-pages-create-protocol-kont-js" */),
  "component---src-pages-create-protocol-mr-js": () => import("./../../../src/pages/create-protocol/MR.js" /* webpackChunkName: "component---src-pages-create-protocol-mr-js" */),
  "component---src-pages-create-protocol-rr-js": () => import("./../../../src/pages/create-protocol/RR.js" /* webpackChunkName: "component---src-pages-create-protocol-rr-js" */),
  "component---src-pages-customers-add-to-customer-portal-js": () => import("./../../../src/pages/customers/add-to-customer-portal.js" /* webpackChunkName: "component---src-pages-customers-add-to-customer-portal-js" */),
  "component---src-pages-customers-customer-portal-js": () => import("./../../../src/pages/customers/customer-portal.js" /* webpackChunkName: "component---src-pages-customers-customer-portal-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-customers-single-js": () => import("./../../../src/pages/customers/single.js" /* webpackChunkName: "component---src-pages-customers-single-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-reservoirs-index-js": () => import("./../../../src/pages/reservoirs/index.js" /* webpackChunkName: "component---src-pages-reservoirs-index-js" */),
  "component---src-pages-reservoirs-single-js": () => import("./../../../src/pages/reservoirs/single.js" /* webpackChunkName: "component---src-pages-reservoirs-single-js" */),
  "component---src-pages-servicelogs-js": () => import("./../../../src/pages/servicelogs.js" /* webpackChunkName: "component---src-pages-servicelogs-js" */),
  "component---src-pages-workorders-js": () => import("./../../../src/pages/workorders.js" /* webpackChunkName: "component---src-pages-workorders-js" */),
  "component---src-pages-workplans-js": () => import("./../../../src/pages/workplans.js" /* webpackChunkName: "component---src-pages-workplans-js" */)
}

