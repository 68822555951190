import styled from 'styled-components';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from './Button';
import Plus from './Plus';
import { colors } from '../utils/siteVars';
import { parseDateTime } from '../utils/parseDate';
import TechnicianFormInput from './TechnicianFormInput';
import { workorderFormDefault, workorderServices } from '../utils/defaults';

const StyledWorkorderForm = styled.form`
  .label {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }
  .pointer {
    cursor: pointer;
  }
  .flex {
    display: flex;
  }
  .jce {
    justify-content: flex-end;
  }
  .workorder-row {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 2fr 3fr 20px;
    column-gap: 0.5rem;
    align-items: flex-start;
    p {
      margin: 0;
      font-weight: 12px;
      margin-bottom: 0.3rem;
    }
    input,
    select,
    textarea {
      margin-bottom: 0.5rem;
      font-size: 12px;
    }
    textarea {
      min-height: revert;
      resize: vertical;
    }
  }
  .bold {
    font-weight: 700;
  }
  @media (max-width: 1000px) {
    .workorder-row {
      grid-template-columns: 2fr 2fr;
      &.headers {
        display: none;
      }
      &.user-row {
        margin-bottom: 1rem;
        position: relative;
        textarea {
          grid-column: 1 / 2;
        }
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          width: 100%;
          background: ${colors.lightGrey};
          content: '';
        }
      }
    }
  }
`;

const WorkorderForm = ({ data, customer, users, handleSubmit }) => {
  const [formData, setFormData] = useState(workorderFormDefault);
  const [sortedWorkorderServices, setSortedWorkorderServices] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sortWorkorderServices = () => {
    const sorted = workorderServices.sort((a, b) => a.localeCompare(b, 'sv'));
    setSortedWorkorderServices(sorted);
  };

  const parsePayload = () => {
    const getReservoir = (row) => {
      const fullReservoir = customer.reservoirs.find(
        (res) => res._id === row.reservoir.id
      );
      if (fullReservoir)
        return {
          id: fullReservoir._id,
          name: fullReservoir.basicInformation.name,
          area: fullReservoir.basicInformation.area,
          area2: fullReservoir.basicInformation.area2,
          area3: fullReservoir.basicInformation.area3,
          area4: fullReservoir.basicInformation.area4,
          volume: fullReservoir.basicInformation.volume,
          volume2: fullReservoir.basicInformation.volume2,
          volume3: fullReservoir.basicInformation.volume3,
          volume4: fullReservoir.basicInformation.volume4,
          compartments: fullReservoir.basicInformation.compartments,
        };
      else return row.reservoir;
    };
    const parsed = {
      ...formData,
      additionalTechnicians: formData.additionalTechnicians?.map(
        (technician) => ({ ...technician, notifications: [] })
      ),
      table: formData.table.map((row) => ({
        ...row,
        reservoir: getReservoir(row),
      })),
    };
    return parsed;
  };

  useEffect(() => {
    sortWorkorderServices();
    if (data) {
      const parsedData = {
        ...data,
        startDateTimeNew: data.startDateTimeNew || data.startDateTime,
        orderPersons: data.orderPersons?.length
          ? data.orderPersons
          : data.orderPerson
          ? [data.orderPerson]
          : [],
      };
      setFormData(parsedData);
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <StyledWorkorderForm
      onSubmit={(e) => {
        e.preventDefault();
        const parsedFormData = parsePayload();
        handleSubmit(parsedFormData, customer);
      }}
    >
      <p className="label">Kontaktpersoner</p>
      <TechnicianFormInput
        name="orderPersons"
        label={'kontaktperson'}
        users={customer.contactPersons}
        value={formData.orderPersons}
        onChange={handleChange}
      />
      <p className="label">Ansvarig servicetekniker</p>
      <TechnicianFormInput
        name="technicians"
        label={'servicetekniker'}
        users={users}
        value={formData.technicians}
        onChange={handleChange}
      />
      <p className="label">Övriga servicetekniker</p>
      <TechnicianFormInput
        name="additionalTechnicians"
        label={'servicetekniker'}
        users={users}
        value={formData.additionalTechnicians}
        onChange={handleChange}
      />
      <p className="label">Ansvarig betongingengör</p>
      {/* eslint-disable-next-line */}
      <select
        value={formData.concreteEngineer || '-'}
        onChange={handleChange}
        name="concreteEngineer"
      >
        <option value="-">Ingen betongingengör</option>
        <option value="Nils">Nils Davant</option>
      </select>
      <p className="label">Arbetet påbörjas (dag och tid)</p>
      <input
        required
        type="datetime-local"
        name="startDateTimeNew"
        value={
          formData.startDateTimeNew
            ? parseDateTime(formData.startDateTimeNew)
            : ''
        }
        onChange={handleChange}
      />
      <p className="label">Mötesplats</p>
      <textarea
        required
        value={formData.meetingSpot.join('\n')}
        name="meetingSpot"
        onChange={(e) => {
          setFormData({
            ...formData,
            meetingSpot: e.target.value.split('\n'),
          });
        }}
      />
      <div className="flex jce">
        {/* eslint-disable-next-line */}
        <select
          onChange={(e) => {
            setFormData({
              ...formData,
              meetingSpot: [e.target.value],
            });
          }}
          value=""
        >
          <option disabled value="">
            Välj reservoar
          </option>
          {customer.reservoirs.map((reservoir) => (
            <option key={reservoir._id} value={reservoir.basicInformation.name}>
              {reservoir.basicInformation.name}
            </option>
          ))}
        </select>
      </div>
      <hr />
      <p className="bold">Rader</p>
      <div className="workorder-row headers">
        <p>Reservoar</p>
        <p>Tjänst</p>
        <p>Etablering</p>
        <p>El</p>
        <p>Övrigt</p>
      </div>
      {formData.table.map((row, i) => (
        <div className="workorder-row user-row" key={`workorder_row_${i}`}>
          {/* eslint-disable-next-line */}
          <select
            value={row.reservoir?.id || ''}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].reservoir.id = e.target.value;
              updated[i].reservoir.name = customer.reservoirs.filter(
                (r) => r._id === e.target.value
              )[0].basicInformation.name;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
            required
          >
            <option disabled value="">
              Välj reservoar
            </option>
            {customer.reservoirs.map((reservoir) => (
              <option
                value={reservoir._id}
                key={`${reservoir._id}__workorder-row__${i}`}
              >
                {reservoir.basicInformation.name}
              </option>
            ))}
          </select>
          <div>
            {/* eslint-disable-next-line */}
            <select
              required
              value={row.service}
              onChange={(e) => {
                const updated = [...formData.table];
                updated[i].service = e.target.value;
                setFormData({
                  ...formData,
                  table: updated,
                });
              }}
            >
              <option value="" disabled>
                Välj tjänst
              </option>

              {sortedWorkorderServices.map((service) => (
                <option key={`workorder_service_option_${i}_${service}`}>
                  {service}
                </option>
              ))}
            </select>
            {row.service === 'Övrigt' && (
              <input
                placeholder="Tjänst (övrigt)"
                value={row.serviceOther || ''}
                onChange={(e) => {
                  const updated = [...formData.table];
                  updated[i].serviceOther = e.target.value;
                  setFormData({
                    ...formData,
                    table: updated,
                  });
                }}
              />
            )}
          </div>
          {/* eslint-disable-next-line */}
          <select
            value={row.establishment || ''}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].establishment = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <option value="" disabled>
              Etablering
            </option>
            <option>-</option>
            <option>Kranbil</option>
            <option>Fastmonterad kranarm på tak</option>
            <option>Telfer med kranarm som tas med av H2O Teknik</option>
            <option>Invändig telfer finns på plats</option>
          </select>
          {/* eslint-disable-next-line */}
          <select
            required
            value={row.electricity || ''}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].electricity = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <option value="" disabled>
              Välj el
            </option>
            <option>-</option>
            <option>1-fas 220 V</option>
            <option>3-fas 400 V</option>
          </select>
          <textarea
            placeholder="Övrigt"
            value={row.other || ''}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].other = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />
          {/* eslint-disable-next-line */}
          <div
            className="pointer"
            onClick={() => {
              const updated = [...formData.table];
              updated.splice(i, 1);
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <Plus cross color={colors.red} />
          </div>
        </div>
      ))}
      <Button
        type="button"
        small
        onClick={() => {
          const updated = [...formData.table];
          updated.push({
            reservoir: {
              id: '',
              name: '',
            },
            service: '',
            establishment: '',
            electricity: '',
            day: '',
            time: '',
            other: '',
          });
          setFormData({
            ...formData,
            table: updated,
          });
        }}
      >
        Lägg till rad
      </Button>
      <hr />
      <p className="label">Övrigt</p>
      <textarea
        name="other"
        value={formData.other.join('\n')}
        onChange={(e) => {
          setFormData({
            ...formData,
            other: e.target.value.split('\n'),
          });
        }}
      />
      <p className="label">Status</p>
      {/* eslint-disable-next-line */}
      <select
        onChange={(e) => {
          setFormData({
            ...formData,
            status: e.target.value,
          });
        }}
        value={formData.status}
      >
        <option>Utkast</option>
        <option>Klar</option>
      </select>
      <Button type="submit" small>
        Spara
      </Button>
    </StyledWorkorderForm>
  );
};

export default WorkorderForm;
