import React from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';

const StyledButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
  padding: 1.2rem 5rem;
  margin-top: 0.5rem;
  border: none;
  background: ${colors.lightBlue};
  color: ${colors.white};
  border-radius: 10px;
  transition: 50ms ease-in-out;
  ${(props) =>
    props.danger ? 'background: ' + colors.red + '; color: white;' : ''}
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    color: ${colors.black};
    background: ${colors.lightGrey};
    opacity: 0.5;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    color: white;
    background: ${colors.lightBlueFaded};
    ${(props) => (props.danger ? 'background: ' + colors.redFaded + ';' : '')}
  }
  ${(props) =>
    props.small
      ? 'font-size: 1rem; font-weight: 400; margin: 0; padding: 0.5rem 1.5rem;'
      : ''}
  ${(props) =>
    props.med
      ? 'font-size: 1rem; font-weight: 400: margin: 0; padding: 1rem 2rem;'
      : ''}
  ${(props) =>
    props.inverted
      ? 'background: ' +
        colors.white +
        '; color: ' +
        colors.lightBlue +
        '; border: 1px solid transparent; &:hover{border: 1px solid ' +
        colors.white +
        ';}'
      : ''}
  ${(props) =>
    props.noBg
      ? 'background: transparent; color: ' +
        colors.lightBlue +
        ';  padding: 0; margin: 0; &:hover{color: ' +
        colors.lightBlueFaded +
        '; background: transparent;}'
      : ''}
      ${(props) =>
    props.noBg && props.danger
      ? 'color: red; &:hover{ color: ' + colors.redFaded + ';}'
      : ''}
      ${(props) =>
    props.white
      ? 'background: ' +
        colors.white +
        '; color: ' +
        colors.black +
        '; box-shadow: 3px 3px 10px rgba(0,0,0,0.1);'
      : ''}
`;

const Button = (props) => {
  return (
    <StyledButton
      data-test={props['data-test']}
      white={props.white}
      style={props.style}
      noBg={props.noBg}
      med={props.med}
      inverted={props.inverted}
      danger={props.look === 'danger'}
      type={props.type || 'button'}
      className={props.className}
      id={props.id}
      onClick={props.onClick}
      small={props.small}
      disabled={props.disabled}
    >
      {props.children}
    </StyledButton>
  );
};

export default Button;
